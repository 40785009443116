import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  catchError,
  EMPTY,
  filter,
  map,
  of,
  switchMap,
  timer,
  withLatestFrom
} from 'rxjs';

import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { AccountService } from '@api/account.service';

import { Toasty } from '@models/toasty.enum';

import {
  notificationsHistoryPageActions,
  notificationsHistorySelectors
} from '../../state';
import { AuthService } from '@app/libs/auth/data-access';

@Injectable()
export class NotificationsHistoryPageEffects implements OnInitEffects {
  readonly unseenNotificationsRefetchPeriod: number = 60 * 1000;

  unSeenNotificationsCount$ = this.store.select<number>(
    notificationsHistorySelectors.selectUnSeenCount
  );
  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private accountService: AccountService,
    private authService: AuthService
  ) {}

  ngrxOnInitEffects(): Action {
    this.store.dispatch(
      notificationsHistoryPageActions.loadUnseenNotificationsCount()
    );

    return { type: '[Notifications History Page Effects]: Init' };
  }

  loadNotificationsHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsHistoryPageActions.loadNotificationsHistory),
      switchMap(({ options }) => {
        return this.accountService.getNotifications(options).pipe(
          map(({ totalCount, entities }) => {
            return notificationsHistoryPageActions.loadNotificationsHistorySuccess(
              {
                totalCount,
                entities
              }
            );
          }),
          catchError(({ error }: HttpErrorResponse) =>
            of(
              notificationsHistoryPageActions.loadNotificationsHistoryFailure({
                error
              })
            )
          )
        );
      })
    )
  );

  loadUnseenNotificationsHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsHistoryPageActions.loadUnseenNotificationsHistory),
      switchMap(({ options }) => {
        return this.accountService.getNotifications(options).pipe(
          map(({ totalCount, entities }) => {
            return notificationsHistoryPageActions.loadUnseenNotificationsHistorySuccess(
              {
                totalCount,
                entities
              }
            );
          }),
          catchError(({ error }: HttpErrorResponse) =>
            of(
              notificationsHistoryPageActions.loadUnseenNotificationsHistoryFailure(
                {
                  error
                }
              )
            )
          )
        );
      })
    )
  );

  updateNotificationSeenStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsHistoryPageActions.updateNotificationSeenStatus),
      withLatestFrom(this.unSeenNotificationsCount$),
      switchMap(([{ notification }, unSeenNotificationsCount]) => {
        return this.accountService
          .updateNotificationSeenStatus(notification.id)
          .pipe(
            switchMap(({ isSeen }) => {
              this.matSnackBar.open('Notification Status Updated', '', {
                panelClass: Toasty.Success
              });
              return [
                notificationsHistoryPageActions.updateNotificationSeenStatusSuccess(
                  {
                    notification: {
                      ...notification,
                      isSeen,
                      dateSeen: new Date()
                    },
                    isSeen
                  }
                ),
                notificationsHistoryPageActions.loadUnseenNotificationsCountSuccess(
                  {
                    count: isSeen
                      ? unSeenNotificationsCount - 1
                      : unSeenNotificationsCount
                  }
                )
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              this.matSnackBar.open('Update Failure', '', {
                panelClass: Toasty.Failure
              });

              return of(
                notificationsHistoryPageActions.updateNotificationSeenStatusFailure(
                  error
                )
              );
            })
          );
      })
    )
  );

  loadUnseenNotificationsCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsHistoryPageActions.loadUnseenNotificationsCount),
      switchMap(() =>
        timer(0, this.unseenNotificationsRefetchPeriod).pipe(
          filter(() => this.authService.hasAccessToken()),
          switchMap(() =>
            this.accountService.getUnseenNotificationsCount().pipe(
              map(({ count }) => {
                return notificationsHistoryPageActions.loadUnseenNotificationsCountSuccess(
                  { count }
                );
              }),
              catchError(() => {
                notificationsHistoryPageActions.loadUnseenNotificationsCountFailure(
                  { count: 0 }
                );
                return EMPTY;
              })
            )
          )
        )
      )
    )
  );
}
